import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { Result } from 'antd';
import Button from '../components/button';
import { useIntl } from 'gatsby-plugin-intl';

const NotFoundPage: React.FC = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title="404: Not found" />
      <main>
        <section className="section-container section-light">
          <Result
            status="404"
            title="404"
            subTitle={intl.formatMessage({ id: 'noMatchPage.INFO_TEXT' })}
            extra={
              <Button
                label={intl.formatMessage({
                  id: 'noMatchPage.GO_BACK_HOME_BUTTON'
                })}
                href="/"
                modeClassName="dark"
              />
            }
          />
        </section>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
